import {
    NewsCarouselConfig,
    NewsCarouselImagePosition,
    NewsCarouselItem,
} from '../components/news-carousel/news-carousel.interface';

export const NEWS_CAROUSEL_ITEMS_EN: NewsCarouselItem[] = [
    {
        heading: 'Easy Offline Storage with idb and the IndexedDB API',
        textSnippet: 'The idb library makes it easy to store data on your device and retrieve it for offline use.',
        imageUrl: '/social_sharing_cardboard_boxes.jpg',
        link: '/offline-storage-indexeddb.en/',
    },
    {
        heading: 'Native Dialogs and the Popover API — What you need to know',
        textSnippet: 'Learn how to animate dialogs and popovers, correctly nest them, and close dialogs on backdrop click.',
        imageUrl: '/social_sharing_pancakes.jpg',
        link: '/native-dialog-and-popover.en/',
    },
    {
        heading: 'Will the CSS Scope Feature replace Angular\'s View Encapsulation?',
        textSnippet: 'Learn how to use native scoping and create code that is easier to read and debug.',
        imageUrl: '/social_sharing_donuts.jpg',
        link: '/css-scope-view-encapsulation.en/',
    },
    {
        heading: 'Support different color themes with a two-color focus indicator',
        textSnippet: 'Create an accessible, universal focus indicator that works in light and dark mode.',
        imageUrl: '/social_sharing_keyboard.jpg',
        link: '/two-color-focus-indicator.en/',
    },
    {
        heading: 'Enhance your Designs with Accessibility Annotations',
        textSnippet: 'Mark headings, alternative texts, links and buttons in your prototypes.',
        imageUrl: '/social_sharing_women_tablet.jpg',
        link: '/design-a11y-annotations.en/',
    },
];

export const NEWS_CAROUSEL_ITEMS_DE: NewsCarouselItem[] = [
    {
        heading: 'Offlinedaten einfach speichern mit idb und der IndexedDB API',
        textSnippet: 'Die idb-Bibliothek macht es einfach, Daten auf dem Gerät zu speichern und sie für die Offline-Nutzung abzurufen.',
        imageUrl: '/social_sharing_cardboard_boxes.jpg',
        link: '/de/offline-storage-indexeddb.de/',
    },
    {
        heading: 'Native Dialoge und die Popover API — Das solltet ihr wissen',
        textSnippet: 'Wie ihr Dialoge und Popovers animiert, sie korrekt verschachtelt und Dialoge beim Hintergrund-Klick schließt.',
        imageUrl: '/social_sharing_pancakes.jpg',
        link: '/de/native-dialog-and-popover.de/',
    },
    {
        heading: 'Wird das CSS Scope Feature die View Encapsulation von Angular ersetzen?',
        textSnippet: 'Lerne Natives Scoping anzuwenden und Code zu erstellen, der einfacher zu lesen und zu debuggen ist.',
        imageUrl: '/social_sharing_donuts.jpg',
        link: '/de/css-scope-view-encapsulation.de/',
    },
    {
        heading: 'Verschiedene Farbthemen mit einem zweifarbigen Fokusindikator unterstützen',
        textSnippet: 'So erzeugt ihr einen barrierefreien, universellen Fokusindikator, der im hellen und dunklen Modus funktioniert.',
        imageUrl: '/social_sharing_keyboard.jpg',
        link: '/de/two-color-focus-indicator.de/',
    },
    {
        heading: 'Optimiert eure Designs mit Barrierefreiheits-Anmerkungen',
        textSnippet: 'Markiert Überschriften, Alternativtexte, Links und Buttons in euren Prototypen.',
        imageUrl: '/social_sharing_women_tablet.jpg',
        link: '/de/design-a11y-annotations.de/',
    },
];

export const NEWS_CAROUSEL_CONFIG_EN: NewsCarouselConfig = {
    carouselLabel: 'Recent blog posts',
    carouselDescription: 'carousel',
    slideDescription: 'slide',
    slideLabel: 'of',
    nextButtonLabel: 'Next slide',
    previousButtonLabel: 'Previous slide',
}

export const NEWS_CAROUSEL_CONFIG_DE: NewsCarouselConfig = {
    carouselLabel: 'Aktuelle Blog-Artikel',
    carouselDescription: 'Karussell',
    slideDescription: 'Artikel',
    slideLabel: 'von',
    nextButtonLabel: 'Nächster Artikel',
    previousButtonLabel: 'Vorheriger Artikel',
}
